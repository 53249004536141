import React, {memo} from "react";

const CameraArrows = ({visible}) => visible &&
		<div className="cameraArrow">
				{['up','right', 'down', 'left'].map(arrow => (
						<button data-type={arrow} key={arrow}>
								<img src={`https://pwa.apps.am/old/img/camera-${arrow}.svg`} alt=""/>
						</button>
				))}
		</div>

export default memo(CameraArrows)
