import React, {memo} from "react";

const CameraBorders = ({visible}) => visible && (
		<div className="cameraBorders" data-top-color="red">
				<span data-side="up"/>
				<span data-side="right"/>
				<span data-side="down"/>
				<span data-side="left"/>
		</div>
)

export default memo(CameraBorders)
