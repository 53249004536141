import {configureStore} from '@reduxjs/toolkit'
import formDataReducer from '../store/formData/FormData.Slice'
import formStepReducer from '../store/formStep/FormStep.Slice'

export default configureStore({
		reducer: {
				formData: formDataReducer,
				formStep: formStepReducer,
		},
})
