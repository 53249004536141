import React, {useState} from "react";
import CaptureImageCamera from "./CaptureImageCamera";
import CaptureImageGallery from "./CaptureImageGallery";
import OrientationWrapper from "../../Helper/OrientationWrapper";

// Define constants for different form steps
export const FORM_STEP = {
		IS_STEP_CAMERA: 'camera',
		IS_STEP_GALLERY: 'gallery'
}

// CaptureImage component with destructured props
const CaptureImage = ({handleFormComplete = f => f,}) => {
		// Initialize state for formStep with a default value of 'camera'
		const [formStep, setFormStep] = useState(FORM_STEP.IS_STEP_CAMERA);
		const commonProps = {setFormStep};
		
		// Render different components based on the formStep state
		return formStep === FORM_STEP.IS_STEP_CAMERA ? (
				// Render CaptureImageCamera component if formStep is 'camera'
				<OrientationWrapper>
						<CaptureImageCamera {...commonProps} />
				</OrientationWrapper>
		) : (
				// Render CaptureImageGallery component if formStep is 'gallery'
				<CaptureImageGallery
						handleFormComplete={handleFormComplete}
						{...commonProps}
				/>
		);
};

// Export the CaptureImage component as the default export
export default CaptureImage;
