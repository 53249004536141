import React from "react";
import {fileToBlob} from "../../../utils/util";
import {StepDirection as SD} from '../../../constants/StepDirection.Constant';
import {useDispatch, useSelector} from "react-redux";
import {updateFormData} from "../../../store/formData/FormData.Slice";
import {currentStepConfigSelector, isFirstSelector, isLastSelector} from "../../../store/formStep/FormStep.Slice";
import {updateFormStep} from "../../../action/FormStep.Action";
import {createItem, generateUniqueHash, getItem, deleteItem, StoreName} from "../../../service/IndexedDB";

const UploadDocuments = ({handleFormComplete = f => f}) => {
				const {
						title,
						informationText,
						uploadButtonTitle,
						addMoreButtonTitle,
						nextTitle,
						backTitle
				} = useSelector(currentStepConfigSelector);
				const isFirst = useSelector(isFirstSelector);
				const isLast = useSelector(isLastSelector);
				const documents = useSelector(({formData}) => formData.documents || []);
				const dispatch = useDispatch();
				
				const handleFileSelect = async ({target}) => {
						try {
								const files = [];
								for (let i = 0; i < target.files.length; i++) {
										const file = target.files[i];
										
										const fileData = {
												fileName: file.name,
												type: file.type,
												size: file.size,
										};
										
										// add file to the indexed DB
										const blobData = await fileToBlob(file);
										const dbKey = await createItem(
												generateUniqueHash(16),
												{...fileData, data: blobData},
												StoreName.DAT_FORM_FILES
										)
										
										files.push({...fileData, dbKey, dataUrl: URL.createObjectURL(file)});
								}
								dispatch(updateFormData({documents: [...documents, ...files]}));
						} catch (e) {
								alert("If you see this message, please take a screenshot of this page.\n" + e.message)
						}
				};
				
				const handleFileRemove = async (index) => {
						const file = documents[index];
						if (file) {
								const {dbKey} = file;
								
								console.log(dbKey);
								dispatch(updateFormData({documents: documents.filter((_, key) => key !== index)}));
								
								const storeFile = getItem(dbKey, StoreName.DAT_FORM_FILES);
								if (storeFile) {
										await deleteItem(dbKey, StoreName.DAT_FORM_FILES);
								}
						}
				};
				
				// Function to handle form submission
				const handleSubmit = () => {
						dispatch(updateFormStep(SD.NEXT))
						
						if (isLast) {
								handleFormComplete();
						}
				};
				
				return (
						<div className={`${documents.length ? 'uploadedPhotoWrap' : 'documentPhotoWrap'} mWrap`}>
								{documents.length ? (
										<>
												<div className="uploadDocuments addMoreDocuments">
														<p>Documents</p>
														<input type="file"
																	 id={'addMore'}
																	 accept="image/*"
																	 multiple={true}
																	 onChange={handleFileSelect}
														/>
														<label htmlFor="addMore">
																<img src="https://pwa.apps.am/old/img/add-more.svg" alt=""/>
																{addMoreButtonTitle}
														</label>
												</div>
												
												<div className="uploadedGallery">
														{documents.map(({dataUrl, fileName}, index) => (
																<div key={index} className='oneDocument'>
																		<img src={dataUrl} alt=""/>
																		<p>
																				<span>{fileName ? fileName : `Document ${index + 1}`}</span>
																				<button onClick={() => handleFileRemove(index)}>
																						<img src="https://pwa.apps.am/old/img/trash.svg" alt=""/>
																				</button>
																		</p>
																</div>
														))}
												</div>
										</>
								) : (
										<>
												<div className="uploadDocuments">
														{title ? (<p dangerouslySetInnerHTML={{__html: title}}/>) : null}
														<div className="selectDocuments">
																<input type="file"
																			 className='ident fileUploaderButton'
																			 id='fileUploader'
																			 accept="image/*"
																			 multiple={true}
																			 onChange={handleFileSelect}
																/>
																<label htmlFor="fileUploader"/>
																<img src="https://pwa.apps.am/old/img/empty-state.svg" alt=""/>
																<span>
																Select images from your gallery or take a photo
														</span>
														</div>
												</div>
												{informationText ? (
														<div className="informationText">
																<p>{informationText}</p>
														</div>
												) : null}
										</>
								)}
								<div className="identifierButton">
										{/* Show the Back button if not the first step */}
										{!isFirst ? (
												<button className="back" onClick={() => dispatch(updateFormStep(SD.BACK))}>
														{backTitle || 'Back'}
												</button>
										) : null}
										
										{documents.length ? (
												<button
														className="ident"
														onClick={handleSubmit}
												>
														{nextTitle || 'Next step'}
												</button>
										) : (
												<div>
														<input type="file"
																	 className='ident fileUploaderButton'
																	 id='fileUploader'
																	 accept="image/*"
																	 multiple={true}
																	 onChange={handleFileSelect}/>
														<label htmlFor="fileUploader">{uploadButtonTitle || 'Upload documents'}</label>
												</div>
										)}
								</div>
						</div>
				);
		}
;

export default UploadDocuments;
