import React from "react";

const GuideMappingImage = ({activeCarSide, guide}) => {
		
		const getGuideMappingImage = () => {
				if (activeCarSide === 'freeView') {
						if (guide === 0) {
								return (
										// correct
										<div className="photographPosition">
												<img className="ellipseSmall" src="https://pwa.apps.am/old/img/ellipse.svg" alt=""/>
												<img className="ellipseBig" src="https://pwa.apps.am/old/img/ellipse.svg" alt=""/>
												<img className="cameraPosition" src="https://pwa.apps.am/old/img/camea-position.svg" alt=""/>
										</div>
								)
						}else if (guide === 1) {
								// You are too close, move back
								return (
										<div className="keepDistance closeDistance">
												<div>
														<img className="closer1"
																 src="https://pwa.apps.am/old/img/arrow-distance-1.svg" alt=""/>
														<img className="closer2"
																 src="https://pwa.apps.am/old/img/arrow-distance-2.svg" alt=""/>
														<img className="closer3"
																 src="https://pwa.apps.am/old/img/arrow-distance-3.svg" alt=""/>
												</div>
										</div>
								)
						} else if (guide === 2) {
								//You are too far, move closer
								return (
										<div className="keepDistance farDistance">
												<div>
														<img className="closer1"
																 src="https://pwa.apps.am/old/img/arrow-distance-1.svg" alt=""/>
														<img className="closer2"
																 src="https://pwa.apps.am/old/img/arrow-distance-2.svg" alt=""/>
														<img className="closer3"
																 src="https://pwa.apps.am/old/img/arrow-distance-3.svg" alt=""/>
												</div>
										</div>
								)
						}
				} else {
						if (guide === 0) {
								return ''
						} else if (guide === 4) {
								//You are too far, move closer
								return (
										<div className="keepDistance farDistance">
												<div>
														<img className="closer1"
																 src="https://pwa.apps.am/old/img/arrow-distance-1.svg" alt=""/>
														<img className="closer2"
																 src="https://pwa.apps.am/old/img/arrow-distance-2.svg" alt=""/>
														<img className="closer3"
																 src="https://pwa.apps.am/old/img/arrow-distance-3.svg" alt=""/>
												</div>
										</div>
								)
						} else if (guide === 5) {
								// You are too close, move back
								return (
										<div className="keepDistance closeDistance">
												<div>
														<img className="closer1"
																 src="https://pwa.apps.am/old/img/arrow-distance-1.svg" alt=""/>
														<img className="closer2"
																 src="https://pwa.apps.am/old/img/arrow-distance-2.svg" alt=""/>
														<img className="closer3"
																 src="https://pwa.apps.am/old/img/arrow-distance-3.svg" alt=""/>
												</div>
										</div>
								)
						} else if (guide === 6) {
								// You are too right, move left
								return (
										<div className="keepDistance moveLeft">
												<div>
														<img className='moveToLeft3' src="https://pwa.apps.am/old/img/move-to-left.svg" alt=""/>
														<img className='moveToLeft2' src="https://pwa.apps.am/old/img/move-to-left.svg" alt=""/>
														<img className='moveToLeft1' src="https://pwa.apps.am/old/img/move-to-left.svg" alt=""/>
												</div>
												<div>
														<img style={{opacity: 0.3}} src="https://pwa.apps.am/old/img/move-to-right.svg"
																 alt=""/>
														<img style={{opacity: 0.3}} src="https://pwa.apps.am/old/img/move-to-right.svg"
																 alt=""/>
														<img style={{opacity: 0.3}} src="https://pwa.apps.am/old/img/move-to-right.svg"
																 alt=""/>
												</div>
										</div>
								)
						} else if (guide === 7) {
								// You are too left, move right
								return (
										<div className="keepDistance moveRight">
												<div>
														<img style={{opacity: 0.3}} src="https://pwa.apps.am/old/img/move-to-left.svg" alt=""/>
														<img style={{opacity: 0.3}} src="https://pwa.apps.am/old/img/move-to-left.svg" alt=""/>
														<img style={{opacity: 0.3}} src="https://pwa.apps.am/old/img/move-to-left.svg" alt=""/>
												</div>
												<div>
														<img className='moveToRight1' src="https://pwa.apps.am/old/img/move-to-right.svg"
																 alt=""/>
														<img className='moveToRight2' src="https://pwa.apps.am/old/img/move-to-right.svg"
																 alt=""/>
														<img className='moveToRight3' src="https://pwa.apps.am/old/img/move-to-right.svg"
																 alt=""/>
												</div>
										</div>
								)
						}
				}
				return ''
		}
		
		return getGuideMappingImage();
		// return (
		// 		<div className="videoBorders">
		// 				<span className='leftTop'/>
		// 				<span className='rightTop'/>
		// 				<span className='leftBottom'/>
		// 				<span className='rightBottom'/>
		// 		</div>
		// );
}

export default GuideMappingImage;
