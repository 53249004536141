import React, {useEffect, useState} from "react";
import {StepsConstant} from "../../constants/Steps.Constant";
import SelectVehicleType from "../Steps/SelectVehicleType/SelectVehicleType";
import ImpactPointSelector from "../Steps/ImpactPoint/ImpactPointSelector";
import UploadDocuments from "../Steps/UploadDocuments/UploadDocuments";
import CaptureImage from "../Steps/CaptureImage/CaptureImage";
import CaptureImage360 from "../Steps/CaptureImage360/CaptureImage360";
import {useSelector} from "react-redux";
import {formDataSelector} from "../../store/formData/FormData.Slice";
import {
		checkerModelPathSelector,
		currentStepSelector,
		detectionModelPathSelector
} from "../../store/formStep/FormStep.Slice";
import {sessionKeypointChecker, sessionKeypointDetection} from "../../service/SessionDetection";

const FormSteps = ({completeFormCallback = f => f}) => {
		const currentStep = useSelector(currentStepSelector);
		const formData = useSelector(formDataSelector);
		const detectionModelPath = useSelector(detectionModelPathSelector);
		const checkerModelPath = useSelector(checkerModelPathSelector);
		
		const [keypointSession, setKeypointSession] = useState();
		const [checkerSession, setCheckerSession] = useState();
		
		useEffect(() => {
				sessionKeypointDetection(detectionModelPath).then(keypointSession => {
						setKeypointSession(keypointSession)
				});
		}, [detectionModelPath]);
		
		useEffect(() => {
				sessionKeypointChecker(checkerModelPath).then(checkerSession => {
						setCheckerSession(checkerSession)
				});
		}, [checkerModelPath]);
		
		const handleFormComplete = () => {
				completeFormCallback(formData);
				alert(
						"Thank you! Your form has been successfully submitted.\n\n\n" +
						(formData.vehicleType ? `Vehicle Type: ${formData.vehicleType}\n` : '') +
						(formData.impactPoints ? `Impact Points: ${formData.impactPoints.join(', ')}\n` : '') +
						(formData.documents ? `Documents: ${formData.documents.length}\n` : '') +
						(formData.carImages ? `Car Images: ${Object.values(formData.carImages).map(({key, images}) => (
								`\nkey: ${key}: img: ${images.length}`
						))}\n` : '')
				);
		}
		
		const getStepDetails = () => {
				const props = {handleFormComplete};
				
				switch (currentStep) {
						case StepsConstant.SELECT_VEHICLE_TYPE:
								return <SelectVehicleType {...props}/>
						case StepsConstant.IMPACT_POINT_SELECTOR:
								return <ImpactPointSelector {...props}/>
						case StepsConstant.UPLOAD_DOCUMENTS:
								return <UploadDocuments {...props}/>
						case StepsConstant.CAPTURE_IMAGE:
								return (<CaptureImage {...props} keypointSession={keypointSession} checkerSession={checkerSession}/>)
						case StepsConstant.CAPTURE_IMAGE_360:
								return (<CaptureImage360 {...props} keypointSession={keypointSession} checkerSession={checkerSession}/>)
						default:
								return null;
				}
		};
		
		return getStepDetails();
};

export default FormSteps;
