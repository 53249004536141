import React from "react";
import {StepDirection as SD} from '../../../constants/StepDirection.Constant';
import {useCamera} from '../../../hooks/useCamera';
import CameraArrows from "../../Helper/Camera.Arrows";
import CameraBorders from "../../Helper/Camera.Borders";
import {FORM_STEP} from "./CaptureImage";
import {useDispatch, useSelector} from "react-redux";
import {updateFormData} from "../../../store/formData/FormData.Slice";
import {currentStepConfigSelector, isFirstSelector} from "../../../store/formStep/FormStep.Slice";
import {updateFormStep} from "../../../action/FormStep.Action";

const CaptureImageCamera = ({setFormStep = f => f}) => {
		const {
				maxImageCount = 10,
				showCameraArrows = true,
				showCameraBorder = true
		} = useSelector(currentStepConfigSelector);
		
		const isFirst = useSelector(isFirstSelector);
		const carImages = useSelector(({formData}) => formData.carImages || []);
		const dispatch = useDispatch()
		
		const {isTorchAvailable, videoRef, captureImage, toggleFlashLight} = useCamera();
		
		// Capture an image and add it to the array of captured images
		const handleCaptureImage = () => {
				dispatch(updateFormData({carImages: [...carImages, captureImage()]}));
		}
		
		return (
				<div className="carCameraWrap mWrap">
						<div className="cameraOptions">
								<div className="camera">
										<video
												ref={videoRef}
												autoPlay
												muted
												style={{display: 'block', width: '100%', objectFit: 'cover'}}
												
												width={'100%'}
												height={'100%'}
												playsInline
										>
										</video>
										{!isFirst && (
												<button className="goBack" onClick={() => dispatch(updateFormStep(SD.BACK))}>
														<img src="https://pwa.apps.am/old/img/go-back-blue.svg" alt=""/>
														go back
												</button>
										)}
										
										<CameraArrows visible={showCameraArrows}/>
										<CameraBorders visible={showCameraBorder}/>
								</div>
								
								<div className="optionItems">
										<div className="flashOption">
												
												<button
														style={{visibility: isTorchAvailable ? 'visible' : 'hidden'}}
														onClick={toggleFlashLight}
												>
														<img src="https://pwa.apps.am/old/img/flash.svg" alt=""/>
												</button>
										</div>
										<div className="shootOption">
												<button
														onClick={handleCaptureImage}
														disabled={carImages.length === maxImageCount}
												>
														<img src="https://pwa.apps.am/old/img/shoot.svg" alt=""/>
												</button>
										</div>
										<div className="imagesOption">
												<button
														data-count={carImages.length}
														style={{visibility: carImages.length ? 'visible' : 'hidden'}}
														onClick={() => setFormStep(FORM_STEP.IS_STEP_GALLERY)}
												>
														{carImages.length ? (
																<img src={carImages[carImages.length - 1]} alt=""/>
														) : null}
												</button>
										</div>
								</div>
						</div>
				</div>
		);
};

export default CaptureImageCamera;
