import {createSlice} from '@reduxjs/toolkit'
import {updateInitialState, updateFormStep} from "../../action/FormStep.Action";
import {AvailableStepsEnum} from "../../constants/Steps.Constant";
import {addOrUpdateItem, StoreName} from "../../service/IndexedDB";

export const formStepSlice = createSlice({
		name: 'formStep',
		initialState: {
				detectionModelPath: null,
				checkerModelPath: null,
				stepsConfig: [],
				currentStepConfig: {},
				availableSteps: [],
				currentStep: null,
				isFirst: false,
				isLast: false,
		},
		reducers: {
				setCurrentStep(state, {payload}) {
						if(state.availableSteps.includes(payload)){
								const newIndex = state.availableSteps.indexOf(payload);
								
								state.currentStep = payload
								state.isFirst = newIndex === 0;
								state.isLast = newIndex === state.availableSteps.length - 1;
								
								state.currentStepConfig = state.stepsConfig.find(({name}) => name === payload);
						}
				},
		},
		extraReducers: (builder) => {
				builder
						.addCase(updateInitialState.fulfilled, (state, {payload: {steps = [],detectionModelPath = null, checkerModelPath = null, currentStep = null}}) => {
								state.stepsConfig = steps.filter(({name}) => AvailableStepsEnum.includes(name));
								state.detectionModelPath = detectionModelPath;
								state.checkerModelPath = checkerModelPath;
								state.availableSteps = state.stepsConfig.map(({name}) => name);
								state.currentStep = currentStep || state.availableSteps[0] || null;

								const currentIndex = state.availableSteps.indexOf(state.currentStep);
								state.isFirst = currentIndex === 0;
								state.isLast = currentIndex === state.availableSteps.length - 1;

								state.currentStepConfig = state.stepsConfig.find(({name}) => name === state.currentStep);
						})
						
						// go to next or back
						.addCase(updateFormStep.fulfilled, (state, {payload}) => {
								const currentIndex = state.availableSteps.indexOf(state.currentStep);
								const newIndex = currentIndex + payload;
								
								if (newIndex >= 0 && newIndex < state.availableSteps.length) {
										state.currentStep = state.availableSteps[newIndex]
										
										state.isFirst = newIndex === 0;
										state.isLast = newIndex === state.availableSteps.length - 1;
										
										state.currentStepConfig = state.stepsConfig.find(({name}) => name === state.currentStep);
										
										addOrUpdateItem('formStep', {
												currentStep: state.currentStep
										}, StoreName.DAT_FORM_DATA)
								}
						});
		},
})

export const {setCurrentStep} = formStepSlice.actions;

export const detectionModelPathSelector = state => state.formStep.detectionModelPath;
export const checkerModelPathSelector = state => state.formStep.checkerModelPath;

export const stepsConfigSelector = state => state.formStep.stepsConfig;
export const currentStepConfigSelector = state => state.formStep.currentStepConfig;
export const availableStepsSelector = state => state.formStep.availableSteps;
export const currentStepSelector = state => state.formStep.currentStep;
export const isFirstSelector = state => state.formStep.isFirst;
export const isLastSelector = state => state.formStep.isLast;

export default formStepSlice.reducer
