export const isImage = mimeType => {
		return mimeType ? ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp', 'image/svg+xml', 'image/tiff'].includes(mimeType) : false;
}

export const fileToBlob = file => {
		return new Promise((resolve, reject) => {
				const reader = new FileReader();
				
				reader.onload = e => {
						resolve(e.target.result);
				};
				reader.onerror = reject;
				reader.readAsArrayBuffer(file);
		});
}

export const fileToArrayBuffer = blobOrFile => {
		return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = e => resolve(e.target.result);
				reader.onerror = reject;
				reader.readAsArrayBuffer(blobOrFile);
		});
}

export const fileToDataUrl = blobOrFile => {
		return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = () => resolve(reader.result);
				reader.onerror = reject;
				reader.readAsDataURL(blobOrFile);
		});
}

export const dataUrlToFile = async (dataUrl, fileName) => {
		const blob = await (await fetch(dataUrl)).blob()
		return new File([blob], fileName, {type: blob.type})
}

export const calculateObjectHash = obj => {
		// Convert the object to a JSON string
		const jsonString = JSON.stringify(obj);
		
		// Create a simple hash by summing the character codes of each character in the JSON string
		let hash = 0;
		for (let i = 0; i < jsonString.length; i++) {
				hash += jsonString.charCodeAt(i);
		}
		
		return hash.toString();
}

export const sendMessageToTelegram = message => {
		const token = '6524205536:AAHF-OHNP9aXhA2oXEtxjvMu5qkyTOatdDI';
		const chatID = '-4011482021';
		
		const url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatID}&parse_mode=html&text=${message}`;
		
		return fetch(url, {method: 'GET'})
				.then((response) => {
						return response.status === 200;
				})
				.catch((error) => {
						return false;
				});
}

export const drawMessage = message => {
		// let elem = document.querySelector('#drawMessage');
		// if (!elem) {
				let elem = document.createElement('p')
				// elem.id = 'drawMessage';
		// }
		elem.innerText = message;
		document.body.prepend(elem)
}
