import React from "react";
import {StepDirection as SD} from '../../../constants/StepDirection.Constant';
import {FORM_STEP} from "./CaptureImage";
import {useDispatch, useSelector} from "react-redux";
import {updateFormData} from "../../../store/formData/FormData.Slice";
import {currentStepConfigSelector, isFirstSelector, isLastSelector} from "../../../store/formStep/FormStep.Slice";
import {updateFormStep} from "../../../action/FormStep.Action";

const CaptureImageGallery = ({
																 handleFormComplete = f => f,
																 setFormStep = f => f
														 }) => {
				const {gallery: {title, retakeButtonTitle, backTitle, nextTitle}} = useSelector(currentStepConfigSelector);
				const isFirst = useSelector(isFirstSelector);
				const isLast = useSelector(isLastSelector);
				const carImages = useSelector(({formData}) => formData.carImages || []);
				const dispatch = useDispatch()
				
				const handleFileRemove = index => {
						dispatch(updateFormData({carImages: carImages.filter((image, key) => key !== index)}))
				}
				
				// Function to handle form submission
				const handleSubmit = () => {
						dispatch(updateFormStep(SD.NEXT))
						
						if (isLast) {
								handleFormComplete();
						}
				};
				
				return (
						<div className="uploadedPhotoWrap mWrap">
								<div className="uploadDocuments addMoreDocuments">
										{title ? <p>{title}</p> : null}
										<button onClick={() => setFormStep(FORM_STEP.IS_STEP_CAMERA)}>
												<img src="https://pwa.apps.am/old/img/shoot-black.svg" alt=""/>
												{retakeButtonTitle}
										</button>
								</div>
								<div className="uploadedGallery">
										{carImages.map((img, index) => (
												<div key={index}>
														<button onClick={() => handleFileRemove(index)}>
																<img src="https://pwa.apps.am/old/img/trash.svg" alt=""/>
														</button>
														<img src={img} alt=""/>
												</div>
										))}
								</div>
								<div className="identifierButton">
										{/* Show the Back button if not the first step */}
										{!isFirst ? (
												<button className="back" onClick={() => setFormStep(FORM_STEP.IS_STEP_CAMERA)}>
														{backTitle || 'Back'}
												</button>
										) : null}
										
										<button
												className="ident"
												disabled={!carImages.length}
												onClick={handleSubmit}
										>
												{nextTitle || 'Next step'}
										</button>
								</div>
						</div>
				);
		}
;

export default CaptureImageGallery;
