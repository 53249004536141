import {useState, useEffect} from 'react';
import {Orientation} from "../constants/Orientation.Constant";

export const useOrientation = (args) => {
		const {onOrientationChange = f => f} = args || {}
		
		const [orientation, setOrientation] = useState(
				window.matchMedia("(orientation: landscape)").matches
						? Orientation.Is_LANDSCAPE
						: Orientation.Is_PORTRAIT
		);
		
		useEffect(() => {
				const mediaQuery = window.matchMedia("(orientation: landscape)");
				
				const handleOrientationChange = event => {
						const newOrientation = event.matches
								? Orientation.Is_LANDSCAPE
								: Orientation.Is_PORTRAIT;
						
						setOrientation(newOrientation);
						onOrientationChange(newOrientation);
				};
				
				mediaQuery.addListener(handleOrientationChange);
				
				handleOrientationChange(mediaQuery);
				
				return () => {
						mediaQuery.removeListener(handleOrientationChange);
				};
		}, []);
		
		return {
				orientation
		};
};
