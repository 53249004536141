import {createSlice} from '@reduxjs/toolkit'
import {addOrUpdateItem, StoreName} from "../../service/IndexedDB";
import {updateInitialState} from "../../action/FormStep.Action";

export const formDataSlice = createSlice({
		name: 'formData',
		initialState: {},
		reducers: {
				updateFormData: (state, {payload}) => {
						const newState = { ...state, ...payload };
						
						Object.keys(newState).forEach(async () => {
								await addOrUpdateItem('formData',newState, StoreName.DAT_FORM_DATA);
						});

						return newState;
				},
		},
		extraReducers: (builder) => {
				builder
						.addCase(updateInitialState.fulfilled, (state, {payload: {formData = {}}}) => {
							Object.assign(state, formData)
						})
		}
})

export const {updateFormData} = formDataSlice.actions

export const formDataSelector = state => state.formData || {};

export default formDataSlice.reducer
