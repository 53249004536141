
import React from "react";

const MessageWrapper = ({
														icon,
														title,
														description,
														backgroundColor,
														actions = []
												}) => {
		
		console.log(actions)
		return (
				<div>
						<div className='orientationFix' style={{
								opacity: 1,
								backgroundColor: backgroundColor || '#'
						}}>
								{icon && <img src={icon} alt=""/>}
								{title && <h4>{title}</h4>}
								{description && <h6>{description}</h6>}
								{actions.length ? (
										<div className="wrapperActions">
												{actions.map(({title, onClick}, index) => (
														<button
																key={index}
																className="wrapperAction"
																onClick={onClick}>
																{title}
														</button>
												))}
										</div>
								): ''}
						</div>
				</div>
		)
}

export default MessageWrapper;
