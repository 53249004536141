export const sidesMapping = [];
sidesMapping[0] = 'front';
sidesMapping[1] = 'rear';
sidesMapping[2] = 'left';
sidesMapping[3] = 'right';
sidesMapping[4] = 'front_left';
sidesMapping[5] = 'front_right';
sidesMapping[6] = 'rear_left';
sidesMapping[7] = 'rear_right';

export const viewsMapping = {
		frontSideView: 1,
		rearSideView: 2,
		frontRightSideView: 3,
		frontLeftSideView: 4,
		rearRightSideView: 5,
		rearLeftSideView: 6,
		freeView: 7
};

export const guideMapping = {
		constraintViews: {
				0 : "correct",
				1 : "Wrong part of the car",
				2 : "Wrong part of the car, rotate left",
				3 : "Wrong part of the car, rotate right",
				4 : "You are too far, move closer",
				5 : "You are too close, move back",
				6 : "You are too right, move left",
				7 : "You are too left, move right",
				8 : "You are too low, move up",
				9 : "You are too up, move down",
				10 : "You taking in the frame also wrong parts, be sure to be perpendicular to the car",
		},
		freeView: {
				0: 'correct',
				1: "You are too close, move back",
				2: "You are too far, move closer"
		},
}

export const infosMapping = {
		top: {
				0 : "correct",
				1 : "not found",
				2 : "too high",
				3 : "too low",
		},
		bottom: {
				0 : "correct",
				1 : "not found",
				2 : "too high",
				3 : "too low",
		},
		left: {
				0 : "correct",
				1 : "not found",
				2 : "too left",
				3 : "too right",
		},
		right: {
				0 : "correct",
				1 : "not found",
				2 : "too left",
				3 : "too right",
		},
		center: {
				0 : "correct",
				1 : "not found",
				2 : "too left",
				3 : "too right",
		},
		wrong: {
				0 : "correct",
				1 : "found a wrong keypoint",
		},
}

export const keypointsMapping = [];
keypointsMapping[1] = 'front_left_headlight_hood_bumper';
keypointsMapping[2] = 'front_left_headlight_hood_fender';
keypointsMapping[3] = 'front_left_headlight_bumper_fender';
keypointsMapping[4] = 'front_left_foglight';
keypointsMapping[5] = 'angle_front_left_bumper_wheel';
keypointsMapping[6] = 'front_left_rim';
keypointsMapping[7] = 'front_left_windscreen_hood_fender';
keypointsMapping[8] = 'front_left_windscreen_roof_pillar';
keypointsMapping[9] = 'front_left_windscreen_pillar_hood';
keypointsMapping[10] = 'front_left_fender_door_window';
keypointsMapping[11] = 'front_left_fender_sill_door';
keypointsMapping[12] = 'mirror_left';
keypointsMapping[13] = 'left_pillars_doors';
keypointsMapping[14] = 'left_pillars_roof';
keypointsMapping[15] = 'bottom_left_doorwindows';
keypointsMapping[16] = 'front_left_handle';
keypointsMapping[17] = 'left_doors_sill';
keypointsMapping[18] = 'left_rear_window_fixedWindow_pillar';
keypointsMapping[19] = 'left_rear_window_fixedWindow_fender';
keypointsMapping[20] = 'left_rear_pillar_fixedWindow_fender';
keypointsMapping[21] = 'rear_left_handle';
keypointsMapping[22] = 'rear_left_door_sill_fender';
keypointsMapping[23] = 'rear_left_wheel_sill_fender';
keypointsMapping[24] = 'rear_left_rim';
keypointsMapping[25] = 'angle_rear_left_bumper_wheel';
keypointsMapping[26] = 'rear_left_windowRear_roof_pillar';
keypointsMapping[27] = 'rear_left_taillightExternal_fender_bumper';
keypointsMapping[28] = 'rear_left_taillightExternal_fender_trunk';
keypointsMapping[29] = 'rear_left_taillightExternal_trunk_bumper';
keypointsMapping[30] = 'angle_left_rear_bumper_trunk';
keypointsMapping[31] = 'angle_right_rear_bumper_trunk';
keypointsMapping[32] = 'rear_right_taillightExternal_trunk_bumper';
keypointsMapping[33] = 'rear_right_taillightExternal_fender_trunk';
keypointsMapping[34] = 'rear_right_taillightExternal_fender_bumper';
keypointsMapping[35] = 'rear_right_windowRear_roof_pillar';
keypointsMapping[36] = 'angle_rear_right_bumper_wheel';
keypointsMapping[37] = 'rear_right_rim';
keypointsMapping[38] = 'rear_right_wheel_sill_fender';
keypointsMapping[39] = 'rear_right_door_sill_fender';
keypointsMapping[40] = 'rear_right_handle';
keypointsMapping[41] = 'right_rear_pillar_fixedWindow_fender';
keypointsMapping[42] = 'right_rear_window_fixedWindow_fender';
keypointsMapping[43] = 'right_rear_window_fixedWindow_pillar';
keypointsMapping[44] = 'right_doors_sill';
keypointsMapping[45] = 'front_right_handle';
keypointsMapping[46] = 'bottom_right_doorwindows';
keypointsMapping[47] = 'right_pillars_roof';
keypointsMapping[48] = 'right_pillars_doors';
keypointsMapping[49] = 'mirror_right';
keypointsMapping[50] = 'front_right_fender_sill_door';
keypointsMapping[51] = 'front_right_fender_door_window';
keypointsMapping[52] = 'front_right_windscreen_pillar_hood';
keypointsMapping[53] = 'front_right_windscreen_roof_pillar';
keypointsMapping[54] = 'front_right_windscreen_hood_fender';
keypointsMapping[55] = 'front_right_rim';
keypointsMapping[56] = 'angle_front_right_bumper_wheel';
keypointsMapping[57] = 'front_right_foglight';
keypointsMapping[58] = 'front_right_headlight_bumper_fender';
keypointsMapping[59] = 'front_right_headlight_hood_fender';
keypointsMapping[60] = 'front_right_headlight_hood_bumper';
keypointsMapping[61] = 'angle_top_left_front_plate';
keypointsMapping[62] = 'angle_top_right_front_plate';
keypointsMapping[63] = 'angle_bottom_left_front_plate';
keypointsMapping[64] = 'angle_bottom_right_front_plate';
keypointsMapping[65] = 'angle_top_left_rear_plate';
keypointsMapping[66] = 'angle_top_right_rear_plate';
keypointsMapping[67] = 'angle_bottom_left_rear_plate';
keypointsMapping[68] = 'angle_bottom_right_rear_plate';
