export const ImpactPoints = {
		IS_LEFT_FRONT_FENDER: "leftFrontFender", // 1
		IS_FRONT_BUMPER: "frontBumper", // 2
		IS_RIGHT_FRONT_FENDER: "rightFrontFender", // 3
		IS_LEFT_FRONT_DOOR: "leftFrontDoor", // 4
		IS_HOOD: "hood", // 5
		IS_RIGHT_FRONT_DOOR: "rightFrontDoor", // 6
		IS_LEFT_REAR_DOOR: "leftRearDoor", // 7
		IS_ROOF: "roof", // 8
		IS_RIGHT_REAR_DOOR: "rightRearDoor", // 9
		IS_LEFT_REAR_FENDER: "leftRearFender", // 10
		IS_REAR_BUMPER: "rearBumper", // 11
		IS_RIGHT_REAR_FENDER: "rightRearFender" // 12
}

export const DefaultImpactPoints = [
		ImpactPoints.IS_FRONT_BUMPER,
		ImpactPoints.IS_LEFT_FRONT_FENDER,
		ImpactPoints.IS_RIGHT_FRONT_FENDER,
		ImpactPoints.IS_HOOD,
		ImpactPoints.IS_ROOF,
		ImpactPoints.IS_LEFT_FRONT_DOOR,
		ImpactPoints.IS_RIGHT_FRONT_DOOR,
		ImpactPoints.IS_LEFT_REAR_DOOR,
		ImpactPoints.IS_RIGHT_REAR_DOOR,
		ImpactPoints.IS_REAR_BUMPER,
		ImpactPoints.IS_LEFT_REAR_FENDER,
		ImpactPoints.IS_RIGHT_REAR_FENDER,
]
