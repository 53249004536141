import React from 'react';
import {Orientation} from "../../constants/Orientation.Constant";
import {useOrientation} from "../../hooks/useOrientation";
import {useSelector} from "react-redux";
import {currentStepConfigSelector} from "../../store/formStep/FormStep.Slice";

function OrientationWrapper({
																children,
																onOrientationChange = () => {
																}
														}) {
		
		const {
				rotatePhoneIcon = "https://pwa.apps.am/old/img/rotate-smartphone.svg",
				rotatePhoneMessage = 'Please rotate your device <br/> to landscape mode.'
		} = useSelector(currentStepConfigSelector).rotation || {};
		
		const {orientation} = useOrientation({onOrientationChange});
		
		return (
				<div>
						{orientation === Orientation.Is_PORTRAIT && (
								<div className='orientationFix'>
										<img src={rotatePhoneIcon} alt=""/>
										<p dangerouslySetInnerHTML={{__html: rotatePhoneMessage}}/>
								</div>
						)}
						{children}
				</div>
		);
}

export default OrientationWrapper;
