import {openDB} from 'idb';

const DB_NAME = 'dat';
const DB_VERSION = 1;

export const StoreName = {
		DAT_FORM_DATA: 'dat_form_data',
		DAT_FORM_FILES: 'dat_form_files',
}

// Function to open the IndexedDB database and create an object store
const openDatabase = async () => await openDB(DB_NAME, DB_VERSION, {
		upgrade(db) {
				Object.values(StoreName).forEach(storeName => {
						if (!db.objectStoreNames.contains(storeName)) {
								db.createObjectStore(storeName);
						}
				})
		},
})
// Function to add or update an item based on its existence
export const addOrUpdateItem = async (key, item, storeName) => {
		const existingItem = await getItem(key, storeName);
		const updatedData = {...item};
		if (existingItem) {
				return await updateItem(key, Object.assign({}, existingItem, updatedData), storeName);
		} else {
				return await createItem(key, item, storeName)
		}
}

// Function to create a new item
export const createItem = async (key, item, storeName) => {
		const db = await openDatabase();
		const tx = db.transaction(storeName, 'readwrite');
		const store = tx.objectStore(storeName);
		const itemId = await store.add(item, key);
		await tx.done;
		return itemId;
}

export const getItem = async (key, storeName) => {
		const db = await openDatabase();
		const tx = db.transaction(storeName, 'readonly');
		const store = tx.objectStore(storeName);
		return store.get(key);
}

// Function to update an item by ID
export const updateItem = async (key, updatedData, storeName) => {
		const db = await openDatabase();
		const tx = db.transaction(storeName, 'readwrite');
		const store = tx.objectStore(storeName);
		await store.put(updatedData, key);
		await tx.done;
}

// Function to delete an item by ID
export const deleteItem = async (key, storeName) => {
		const db = await openDatabase();
		const tx = db.transaction(storeName, 'readwrite');
		const store = tx.objectStore(storeName);
		await store.delete(key);
		await tx.done;
}

export const isIndexedDBSupported = () => window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

export const generateUniqueHash = length => {
		const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'; // Characters to use in the hash
		let hash = '';
		
		for (let i = 0; i < length; i++) {
				const randomIndex = Math.floor(Math.random() * charset.length);
				hash += charset.charAt(randomIndex);
		}
		
		return hash;
}
