import React from "react";
import {sendMessageToTelegram} from "../../utils/util";

class ErrorBoundary extends React.Component {
		state = {
				hasError: false,
				error: {message: '', stack: ''},
				info: {componentStack: ''}
		};
		
		static getDerivedStateFromError = error => {
				return {hasError: true};
		};
		
		componentDidCatch = (error, info) => {
				this.setState({error, info});
				
				sendMessageToTelegram(
						`<b>Message</b>: ${error.message}%0A
						<b>Component Stack</b>: ${info.componentStack}`
				).then(result => {
						console.log(result)
				})
		};
		
		render() {
				const {hasError, error, info} = this.state;
				const {children} = this.props;
				
				return hasError ? (
						<>
								<h1>Something went wrong</h1>
								<h3>{error.message}</h3>
								<h4>{info.componentStack}</h4>
						</>
				) : children;
		}
}

export default ErrorBoundary;
