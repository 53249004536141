export const StepsConstant = {
		SELECT_VEHICLE_TYPE: 'selectVehicleType',
		IMPACT_POINT_SELECTOR: 'impactPointSelector',
		UPLOAD_DOCUMENTS: 'uploadDocuments',
		CAPTURE_IMAGE: 'captureImage',
		CAPTURE_IMAGE_360: 'captureImage360'
}

export const AvailableStepsEnum = [
		StepsConstant.SELECT_VEHICLE_TYPE,
		StepsConstant.IMPACT_POINT_SELECTOR,
		StepsConstant.UPLOAD_DOCUMENTS,
		StepsConstant.CAPTURE_IMAGE,
		StepsConstant.CAPTURE_IMAGE_360
]
