import React, {memo, useEffect, useMemo, useRef, useState} from "react";
import {StepDirection as SD} from '../../../constants/StepDirection.Constant';
import {useCamera} from "../../../hooks/useCamera";
import {FORM_STEP} from "./CaptureImage360";
import MessageWrapper from "../../Helper/MessageWrapper";
import usePageVisibility from "../../../hooks/usePageVisibility";
import {
		resizeCanvas,
		runDetectionOptimized
} from "../../../service/SessionDetection";
import {useDispatch, useSelector} from "react-redux";
import {updateFormData} from "../../../store/formData/FormData.Slice";
import {currentStepConfigSelector, isFirstSelector} from "../../../store/formStep/FormStep.Slice";
import {updateFormStep} from "../../../action/FormStep.Action";
import {createItem, generateUniqueHash, StoreName} from "../../../service/IndexedDB";
import GuideMappingImage from "../../Helper/GuideMappingImage";

const CaptureImage360Camera = ({
																	 setFormStep = f => f,
																	 keypointSession = null,
																	 checkerSession = null
															 }) => {
		const {carSides = []} = useSelector(currentStepConfigSelector);
		const isFirst = useSelector(isFirstSelector);
		const carImages = useSelector(({formData}) => formData.carImages || {});
		const dispatch = useDispatch()
		
		const {
				accessToCamera,
				isTorchAvailable,
				isCameraInitialized,
				videoRef,
				captureImage,
				toggleFlashLight,
				setupCamera,
				stopCamera
		} = useCamera();
		
		const visibilityStatus = usePageVisibility();
		
		const activeCarSideRef = useRef(null);
		const canvasRef = useRef(null);
		
		const [activeCarSide, setActiveCarSide] = useState();
		const [intervalId, setIntervalId] = useState(null)
		const [guide, setGuide] = useState(null);
		const [guideResult, setGuideResult] = useState('');
		
		useEffect(() => {
				if (visibilityStatus) {
						stopCamera();
				} else {
						setupCamera();
				}
		}, [visibilityStatus])
		
		// Check if a car side has available image slots
		const isSideAvailable = side => {
				const {maxImageCount = 1} = side || {};
				const {images = []} = carImages[side.key] || {};
				return images.length < maxImageCount;
		};
		
		const isCaptureAvailable = useMemo(() => {
				if (!activeCarSide || !isCameraInitialized) return false;
				return isSideAvailable(
						carSides.find(({key}) => key === activeCarSide)
				);
		}, [activeCarSide, carImages, carSides, isCameraInitialized]);
		
		const isAllOffTheSidesCaptured = useMemo(() => carSides.every((side) => {
				const {maxImageCount = 1} = side;
				const {images = []} = carImages[side.key] || {};
				return images.length >= maxImageCount;
		}), [carImages, carSides]);
		
		// Update the activeCarSideRef whenever activeCarSide changes
		useEffect(() => {
				if (activeCarSideRef.current) {
						activeCarSideRef.current.scrollIntoView({
								behavior: 'smooth', // You can use 'auto' or 'smooth' for scrolling behavior
								block: 'center',    // You can use 'start', 'center', 'end', or 'nearest'
						});
				}
		}, [activeCarSide]);
		
		useEffect(() => {
				// Update the form data and check if we should move to the next side
				const currentActiveSide = carSides.find(({key}) => key === activeCarSide);
				if (!currentActiveSide || !isSideAvailable(currentActiveSide)) {
						goToNextAvailableSide();
				}
				
				if (isAllOffTheSidesCaptured) {
						stopCamera();
						setFormStep(FORM_STEP.IS_STEP_GALLERY)
				}
		}, [carImages]);

		useEffect(() => {
				// console.clear()
				if (intervalId) {
						clearInterval(intervalId)
						console.log('clear interval')
						setIntervalId(null)
				}
				
				if (activeCarSide && isCaptureAvailable) {
						const ctx = canvasRef.current.getContext('2d', {
								willReadFrequently: true
						});
						resizeCanvas(videoRef.current, canvasRef.current);
						
						const newIntervalId = setInterval(async () => {
								if (videoRef.current && keypointSession && checkerSession) {
										const startTime = performance.now();
										
										const result = await runDetectionOptimized(videoRef.current, ctx, keypointSession, checkerSession, activeCarSide)
										
										if (result.guide !== guide) {
												setGuide(result.guide)
										}
										if (result.guideResult !== guideResult) {
												setGuideResult(result.guideResult)
										}
										const creation_elapsed = (performance.now() - startTime);
										
										console.log(`times, in: ${creation_elapsed}`, activeCarSide)
								}
						}, 400)
						setIntervalId(newIntervalId)
				}
		}, [activeCarSide, isCaptureAvailable, keypointSession, checkerSession]);
		
		// Capture an image and update state
		const handleCaptureImage = async () => {
				const capturedImage = await captureImage();
				
				const carSide = carSides.find(side => side.key === activeCarSide);
				
				const dbKey = await createItem(
						generateUniqueHash(16),
						{data: capturedImage},
						StoreName.DAT_FORM_FILES
				)
				
				dispatch(updateFormData({
						carImages: {
								...carImages,
								[activeCarSide]: {
										key: activeCarSide,
										title: carSide?.title,
										images: [
												...(carImages[activeCarSide]?.images || []),
												{
														dbKey,
														dataUrl: URL.createObjectURL(new Blob([capturedImage]))
												},
										],
								}
						}
				}))
		};
		
		// Go to the next available car side
		const goToNextAvailableSide = () => {
				if (!activeCarSide) {
						setActiveCarSide(carSides[0].key);
				}
				
				let nextSideIndex = carSides.findIndex(({key}) => key === activeCarSide) + 1;
				let loops = 0;
				while (loops < carSides.length) {
						if (nextSideIndex === carSides.length) {
								nextSideIndex = 0;
								loops++;
						}
						
						const nextSide = carSides[nextSideIndex];
						if (isSideAvailable(nextSide)) {
								setActiveCarSide(nextSide.key);
								return;
						}
						
						nextSideIndex++;
						if (loops >= carSides.length) {
								break;
						}
				}
				
				setActiveCarSide(null);
		};
		
		return (
				<div className="carCameraWrap mWrap">
						{accessToCamera ? (
								<div className="cameraOptions">
										<div className="camera">
												{guideResult && (
														<div className="attention">
																<img src="https://pwa.apps.am/old/img/attention.svg" alt=""/>
																&nbsp;{guideResult}
														</div>
												)}
												<video
														ref={videoRef}
														autoPlay
														muted
														style={{display: "block", width: "100%", objectFit: "cover"}}
														width={"100%"}
														height={"100%"}
														playsInline
												/>
												<canvas
														style={{
																// display: "none"
																position: 'absolute',
																top: 0,
																left: 0
														}}
														width={"100%"}
														height={"100%"}
														ref={canvasRef}
												/>
												<GuideMappingImage
														activeCarSide={activeCarSide}
														guide={guide}
												/>
												
												<div className="modal fade show imageCollection" style={{display: "block"}}>
														<div className="modal-dialog">
																<div className="modal-content">
																		
																		<div className="modal-body">
																				<img src="https://pwa.apps.am/old/img/modal-check.svg" alt=""/>
																				<p>Images have been collected</p>
																				<span>You can retake them at any time</span>
																		</div>
																		<div className="modal-footer">
																				<button type="button" className="cancel">Cancel</button>
																				<button type="button" className="nextStep">Next step</button>
																		</div>
																</div>
														</div>
												</div>
												{!isFirst && (
														<button className="goBack" onClick={() => {
																// stopDetecting();
																stopCamera();
																dispatch(updateFormStep(SD.BACK))
														}}>
																<img src="https://pwa.apps.am/old/img/go-back-blue.svg" alt=""/>
																go back
														</button>
												)}
										</div>
										<div className="optionItems">
												
												{carSides.length && (
														<div className="carSides">
																{carSides.map(({key, title, img, imgActive}) => (
																		<div
																				key={key}
																				className={
																						`${activeCarSide === key ? 'active' : ''} ${carImages[key]?.images?.[carImages[key].images.length - 1] ? 'imgExist' : ''}`
																				}
																				/*style={
																																							carImages[key] && carImages[key].images[carImages[key].images.length - 1] ? {backgroundImage: `url(${carImages[key].images[carImages[key].images.length - 1]})`} : {}
																																			}*/
																				onClick={() =>
																						activeCarSide === key
																								? setActiveCarSide(null)
																								: setActiveCarSide(key)
																				}
																				ref={activeCarSide === key ? activeCarSideRef : null}
																		>
																				<img
																						src={`${carImages[key]?.images?.[carImages[key].images.length - 1] ? imgActive : img}`}
																						alt={title}/>
																				<span>{title}</span>
																		</div>
																))}
														</div>
												)}
												
												
												<div className="shootOption">
														<button onClick={handleCaptureImage} disabled={!isCameraInitialized || !isCaptureAvailable}>
																<img src="https://pwa.apps.am/old/img/shoot.svg" alt=""/>
														</button>
												</div>
												{/*<div className="imagesOption">
														<button
																data-count={carImagesCount}
																style={{visibility: carImagesCount ? 'visible' : 'hidden'}}
																onClick={() => {
																		stopDetecting();
																		stopCamera();
																		setFormStep(FORM_STEP.IS_STEP_GALLERY);
																}}
																disabled={!isAllOffTheSidesCaptured}
														>
																{lastImage ? <img src={lastImage} alt=""/> : null}
														</button>
												</div>*/}
												<div className="flashOption">
														<button className='flashBtn'
																		style={{visibility: isTorchAvailable ? "visible" : "hidden"}}
																		onClick={toggleFlashLight}
														>
																<img src="https://pwa.apps.am/old/img/flash-new.svg" alt=""/>
														</button>
												</div>
										</div>
								</div>
						) : (
								<MessageWrapper
										title="Camera Access Required"
										description="To proceed, please grant this app access to your camera."
										icon="https://pwa.apps.am/old/img/warning-white.svg"
										backgroundColor="#1f2d40"
										actions={[
												{
														title: 'Try again ',
														onClick: () => {
																setupCamera()
														}
												}
										]}
								/>
						)}
						{/*<div className="modal-backdrop show"></div>*/}
				</div>
		);
};

export default memo(CaptureImage360Camera);
