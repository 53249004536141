import React from "react";
import {DefaultImpactPoints} from "../../../constants/ImpactPoints.Constant";
import {StepDirection as SD} from "../../../constants/StepDirection.Constant";
import {useDispatch, useSelector} from "react-redux";
import {updateFormData} from "../../../store/formData/FormData.Slice";
import {currentStepConfigSelector, isFirstSelector, isLastSelector} from "../../../store/formStep/FormStep.Slice";
import {updateFormStep} from "../../../action/FormStep.Action";

const ImpactPointSelector = ({handleFormComplete = f => f}) => {
		const {
				title,
				vehicleImg,
				availableImpactPoints = DefaultImpactPoints,
				informationText,
				nextTitle,
				backTitle
		} = useSelector(currentStepConfigSelector);
		const isFirst = useSelector(isFirstSelector);
		const isLast = useSelector(isLastSelector);
		const impactPoints = useSelector(({formData}) => formData.impactPoints || []);
		const dispatch = useDispatch()
		
		// Function to handle form submission
		const handleSubmit = () => {
				dispatch(updateFormStep(SD.NEXT))
				
				if (isLast) {
						handleFormComplete();
				}
		};
		
		// Function to handle impact points changes
		const handleCheckboxChange = event => {
				const {value} = event.target;
				
				// Check if the impact point is already selected
				if (impactPoints.includes(value)) {
						// If selected, remove it from the list
						dispatch(updateFormData({impactPoints: impactPoints.filter(point => point !== value)}))
				} else {
						// If not selected, add it to the list
						dispatch(updateFormData({impactPoints: [...impactPoints, value]}))
				}
		};
		
		return (
				<div className="accidentWrap mWrap">
						<div className="vehicleImpact">
								{title ? (<p dangerouslySetInnerHTML={{__html: title}}/>) : null}
								
								<div className="vehicleConstruction">
										<img src={vehicleImg} alt=""/>
										{availableImpactPoints.map(impactPoint => (
												<React.Fragment key={impactPoint}>
														<input
																type="checkbox"
																id={impactPoint}
																value={impactPoint}
																onChange={handleCheckboxChange}
																checked={impactPoints.includes(impactPoint)}
														/>
														<label htmlFor={impactPoint} data-part={impactPoint}/>
												</React.Fragment>
										))}
								</div>
						
						</div>
						{informationText ? (
								<div className="informationText">
										<p>{informationText}</p>
								</div>
						) : null}
						<div className="identifierButton">
								{/* Show the Back button if not the first step */}
								{!isFirst ? (
										<button className="back" onClick={() => dispatch(updateFormStep(SD.BACK))}>
												{backTitle || 'Back'}
										</button>
								) : null}
								{/* Disable the Identify button if no vehicle type is selected */}
								<button
										disabled={!impactPoints.length}
										className="ident"
										onClick={handleSubmit}
								>
										{nextTitle || 'Next step'}
								</button>
						</div>
				</div>
		)
};

export default ImpactPointSelector
