import React, {useState} from "react";
import {StepDirection as SD} from '../../../constants/StepDirection.Constant';
import {FORM_STEP} from "./CaptureImage360";
import {useDispatch, useSelector} from "react-redux";
import {updateFormData} from "../../../store/formData/FormData.Slice";
import {currentStepConfigSelector, isFirstSelector, isLastSelector} from "../../../store/formStep/FormStep.Slice";
import {updateFormStep} from "../../../action/FormStep.Action";
import {deleteItem, StoreName} from "../../../service/IndexedDB";
import {sendMessageToTelegram} from "../../../utils/util";

// Define a React component called CaptureImage360Gallery
const CaptureImage360Gallery = ({
																		handleFormComplete = f => f,
																		setFormStep = f => f
																}) => {
				const dispatch = useDispatch();
				
				const {gallery: {title, retakeButtonTitle, backTitle, nextTitle}} = useSelector(currentStepConfigSelector);
				const isFirst = useSelector(isFirstSelector);
				const isLast = useSelector(isLastSelector);
				const carImages = useSelector(({formData}) => formData.carImages || []);

				// State for tracking whether all sides of the vehicle have been captured
				const [isAllOffTheSidesCaptured, setIsAllOffTheSidesCaptured] = useState(true);
				
				// Function to handle the removal of a file (image) from carImages
				const handleFileRemove = async (key, index) => {
						if (
								!carImages[key] ||
								!carImages[key].images ||
								index < 0 ||
								index >= carImages[key].images.length
						) {
								return;
						}
						const newImages = [...carImages[key].images];
						if(newImages[index]){
								await deleteItem(newImages[index].dbKey, StoreName.DAT_FORM_FILES)
						}
						newImages.splice(index, 1);

						const newCarImages = {
								...carImages,
								[key]: {...carImages[key], images: newImages},
						};

						dispatch(updateFormData({
								carImages: newCarImages,
						}));
						setIsAllOffTheSidesCaptured(false);
				};
				
				// Function to handle form submission
				const handleSubmit = () => {
						dispatch(updateFormStep(SD.NEXT))
						
						// If it's the last step, call handleFormComplete
						if (isLast) {
								handleFormComplete();
						}
				};
				
				// Render the component's UI
				return (
						<div className="uploadedPhotoWrap mWrap">
								<div className="uploadDocuments addMoreDocuments">
										{title ? <p>{title}</p> : null}
										{!isAllOffTheSidesCaptured && (
												<button onClick={() => setFormStep(FORM_STEP.IS_STEP_CAMERA)}>
														<img src="https://pwa.apps.am/old/img/shoot-black.svg" alt=""/>
														{retakeButtonTitle}
												</button>
										)}
								</div>
								<div className="uploadedGallery">
										{Object.values(carImages).map(({images, key, title}) => images.map(({dataUrl}, index) => (
														<div key={`${key}-${index}`} id={`${key}-${index}`} className='oneDocument'>
																<img src={dataUrl} alt=""/>
																<p>
																		<span>{title}</span>
																		<button onClick={() => handleFileRemove(key, index)}>
																				<img
																						src="https://pwa.apps.am/old/img/trash.svg"
																						alt=""/>
																		</button>
																</p>
														</div>
												
												))
										)}
								</div>
								<div className="identifierButton">
										{/* Show the Back button if not the first step */}
										{!isFirst && !isAllOffTheSidesCaptured && (
												<button className="back" onClick={() => setFormStep(FORM_STEP.IS_STEP_CAMERA)}>
														{backTitle || 'Back'}
												</button>
										)}
										
										<button
												className="ident"
												disabled={!isAllOffTheSidesCaptured}
												onClick={handleSubmit}
										>
												{nextTitle || 'Next step'}
										</button>
								</div>
						</div>
				);
		}
;

export default CaptureImage360Gallery;
