import React from "react";
import {StepDirection as SD} from "../../../constants/StepDirection.Constant";
import {useDispatch, useSelector} from "react-redux";
import {updateFormData} from "../../../store/formData/FormData.Slice";
import {currentStepConfigSelector, isFirstSelector, isLastSelector} from "../../../store/formStep/FormStep.Slice";
import {updateFormStep} from "../../../action/FormStep.Action";

const SelectVehicleType = ({handleFormComplete = f => f}) => {
		
		const {title, types = [], nextTitle, backTitle} = useSelector(currentStepConfigSelector);
		const isFirst = useSelector(isFirstSelector);
		const isLast = useSelector(isLastSelector);
		const vehicleType = useSelector(({formData}) => formData.vehicleType);
		const dispatch = useDispatch()
		
		// Function to handle form submission
		const handleSubmit = () => {
				dispatch(updateFormStep(SD.NEXT))
				
				if (isLast) {
						handleFormComplete();
				}
		};
		
		return (
				<div className="notIdentifierWrap mWrap">
						<div className="isIdentifier">
								{/* Display the title or a default message */}
								{title ? (<p>{title}</p>) : null}
								<div className="vehicleList">
										<ul>
												{/* Map through types and create radio buttons */}
												{types.map(({key, title, icon}) => (
														<li key={key}>
																<input
																		type="radio"
																		value={key}
																		id={`vehicle-type-${key}`}
																		checked={key === vehicleType}
																		// Update vehicleType when the radio button changes
																		onChange={({target: {value}}) => {
																				dispatch(updateFormData({vehicleType: value}))
																		}}
																/>
																<label htmlFor={`vehicle-type-${key}`}>
																		{icon ? <img src={icon} alt=""/> : null}
																		{title}
																</label>
														</li>
												))}
										</ul>
								</div>
						</div>
						<div className="identifierButton">
								{/* Show the Back button if not the first step */}
								{!isFirst ? (
										<button className="back" onClick={() => dispatch(updateFormStep(SD.BACK))}>
												{backTitle || 'Back'}
										</button>
								) : null}
								{/* Disable the Identify button if no vehicle type is selected */}
								<button
										disabled={!vehicleType}
										className="ident"
										onClick={handleSubmit}
								>
										{nextTitle || 'Identify'}
								</button>
						</div>
				</div>
		);
};

export default SelectVehicleType;
