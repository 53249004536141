import {useState, useRef} from 'react';

export const useCamera = () => {
		
		const [accessToCamera, setAccessToCamera] = useState(true);
		const [localStream, setLocalStream] = useState(null);
		const [torch, setTorch] = useState(false);
		const [isTorchAvailable, setIsTorchAvailable] = useState(false);
		const [isCameraInitialized, setIsCameraInitialized] = useState(false);
		const videoRef = useRef(null);
		
		const setupCamera = async () => {
				if (!isCameraInitialized) {
						setAccessToCamera(true)
						try {
								const constraints = {
										video: {
												facingMode: 'environment', // Use the back camera
												width: {ideal: 1920, min: 640}, // Preferred and minimum width
												height: {ideal: 1080, min: 480}, // Preferred and minimum height
												// aspectRatio: {exact: 19 / 9}
										},
										audio: false,
										advanced: [{zoom: 100}]
								}
								const stream = await navigator.mediaDevices.getUserMedia(constraints);
								
								if (stream && 'getVideoTracks' in stream) {
										try {
												const tracks = stream.getVideoTracks();
												if (tracks.length > 0) {
														const capabilities = tracks[0].getCapabilities();
														setIsTorchAvailable('torch' in capabilities && capabilities.torch);
												}
										} catch (e) {
										}
								}
								
								videoRef.current.srcObject = stream;
								setIsCameraInitialized(true)
								setLocalStream(stream);
								
								return stream;
						} catch (e) {
								setAccessToCamera(false)
								// alert("If you see this message, please take a screenshot of this page.\n"+e.message)
						}
				}
				return false;
		};
		
		const stopCamera = async () => {
				if (localStream && isCameraInitialized) {
						let result = false;
						localStream.getTracks().forEach(track => {
								track.stop();
								if (!result) {
										result = true;
								}
						});
						
						if (result) {
								setLocalStream(null);
								setIsCameraInitialized(false);
								setIsTorchAvailable(false);
						}
				}
		}
		
		const captureImage = () => {
				const videoElement = videoRef.current;
				
				const canvas = document.createElement('canvas');
				canvas.width = videoElement.videoWidth;
				canvas.height = videoElement.videoHeight;
				
				const context = canvas.getContext('2d');
				context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
				
				return new Promise(resolve => {
						canvas.toBlob(blob => {
								const reader = new FileReader();
								
								reader.onload = function () {
										resolve(reader.result);
								};
								
								reader.readAsArrayBuffer(blob);
						}, "image/png");
				});
		};
		
		const toggleFlashLight = async () => {
				if (localStream && isTorchAvailable) {
						const videoTrack = localStream.getVideoTracks()[0];
						await videoTrack.applyConstraints({
								advanced: [{torch: !torch}]
						});
						setTorch(!torch)
				}
		};
		
		return {
				accessToCamera,
				torch,
				isTorchAvailable,
				isCameraInitialized,
				videoRef,
				setupCamera,
				stopCamera,
				captureImage,
				toggleFlashLight,
		};
};
