import {useEffect} from "react";
import FormSteps from "./Forms/Form.Steps";
import ErrorBoundary from "./Helper/Error.Boundary";
import {useDispatch} from "react-redux";
import {updateInitialState} from "../action/FormStep.Action";
import {getItem, StoreName} from "../service/IndexedDB";

import 'bootstrap/scss/bootstrap.scss';
import '@style/base.scss';
import '@style/home.scss';

const App = ({config: {steps = [], completeFormCallback = f => f, detectionModelPath, checkerModelPath}}) => {
		const dispatch = useDispatch()
		
		const fetchDataFromDbAndUpdateState = async () => {
				const formStepData = await fetchFormStepDataFromDB();
				const formData = await fetchFormDataFromDB();
				
				dispatch(updateInitialState(Object.assign({
						steps,
						detectionModelPath,
						checkerModelPath
				}, formStepData, formData)));
		}
		
		const fetchFormStepDataFromDB = async () => {
				const formStep = await getItem('formStep', StoreName.DAT_FORM_DATA);
				return formStep && 'currentStep' in formStep ? {currentStep: formStep.currentStep} : {}
		}
		
		const fetchFormDataFromDB = async () => {
				const formData = await getItem('formData', StoreName.DAT_FORM_DATA);
				
				if (!formData) {
						return {};
				}
				
				if (formData.documents) {
						const documentImagePromises = formData.documents
								.filter((doc) => 'dbKey' in doc)
								.map((doc) =>
										getItem(doc.dbKey, StoreName.DAT_FORM_FILES).then((storedImage) => {
												if (storedImage) {
														const imageBuffer = storedImage.data;
														const imageBlob = new Blob([imageBuffer]);
														doc.dataUrl = URL.createObjectURL(imageBlob);
												}
										})
								);
						
						await Promise.all(documentImagePromises);
				}
				
				if (formData.carImages) {
						for (const key in formData.carImages) {
								if (formData.carImages.hasOwnProperty(key)) {
										for (const image of formData.carImages[key].images) {
												const carImage = await getItem(image.dbKey, StoreName.DAT_FORM_FILES);
												if (carImage) {
														const imageBuffer = carImage.data;
														const imageBlob = new Blob([imageBuffer]);
														image.dataUrl = URL.createObjectURL(imageBlob);
												}
										}
								}
						}
				}
				
				return {formData}
		};
		
		const loadImagesInBackground = async () => {
				const imageUrls = [
						'https://pwa.apps.am/old/img/tag.svg',
						'https://pwa.apps.am/old/img/tag-active.svg',
						'https://pwa.apps.am/old/img/ellipse.svg',
						'https://pwa.apps.am/old/img/camea-position.svg',
						'https://pwa.apps.am/old/img/arrow-distance-1.svg',
						'https://pwa.apps.am/old/img/arrow-distance-2.svg',
						'https://pwa.apps.am/old/img/arrow-distance-3.svg',
						'https://pwa.apps.am/old/img/move-to-left.svg',
						'https://pwa.apps.am/old/img/move-to-right.svg',
				];
				
				const imagePromises = imageUrls.map(url => new Promise((resolve, reject) => {
						const img = new Image();
						img.onload = () => resolve(img);
						img.onerror = reject;
						img.src = url;
				}))
				
				await Promise.all(imagePromises);
		}
		
		useEffect(() => {
				fetchDataFromDbAndUpdateState();
				loadImagesInBackground();
		}, []);
		
		return (
				<ErrorBoundary fallback={<p>Something went wrong</p>}>
						<FormSteps
								completeFormCallback={completeFormCallback}
						/>
				</ErrorBoundary>
		);
}

export default App;
