import React, {useEffect, useState, useMemo} from "react";
import CaptureImage360Camera from "./CaptureImage360Camera";
import CaptureImage360Gallery from "./CaptureImage360Gallery";
import OrientationWrapper from "../../Helper/OrientationWrapper";
import {sessionKeypointChecker, sessionKeypointDetection} from "../../../service/SessionDetection";
import {useSelector} from "react-redux";
import {currentStepConfigSelector} from "../../../store/formStep/FormStep.Slice";

// Define constants for different form steps
export const FORM_STEP = {
		IS_STEP_CAMERA: 'camera',
		IS_STEP_GALLERY: 'gallery'
}

const CaptureImage360 = ({handleFormComplete = f => f, keypointSession = null, checkerSession = null}) => {
		const [formStep, setFormStep] = useState();
		
		const {carSides = []} = useSelector(currentStepConfigSelector);
		const carImages = useSelector(({formData}) => formData.carImages || {});
		
		useEffect(() => {
				const isAllOffTheSidesCaptured = () => carSides.every((side) => {
						const {maxImageCount = 1} = side;
						const {images = []} = carImages[side.key] || {};
						return images.length >= maxImageCount;
				});
				
				setFormStep(isAllOffTheSidesCaptured() ? FORM_STEP.IS_STEP_GALLERY : FORM_STEP.IS_STEP_CAMERA)
		}, [])
		
		const getStepDetails = () => {
				const commonProps = {setFormStep};
				
				switch (formStep) {
						case FORM_STEP.IS_STEP_CAMERA:
								return (
										<OrientationWrapper>
												<CaptureImage360Camera {...commonProps} keypointSession={keypointSession}
																							 checkerSession={checkerSession}/>
										</OrientationWrapper>
								);
						case FORM_STEP.IS_STEP_GALLERY:
								return (
										<CaptureImage360Gallery
												handleFormComplete={handleFormComplete}
												{...commonProps}
										/>
								);
						default:
								return null;
				}
		};
		
		return getStepDetails();
}

// Export the CaptureImage component as the default export
export default CaptureImage360;
