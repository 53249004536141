import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import {Provider} from 'react-redux'
import store from './store/store';

// for Production
export const init = (config) => {
    const root = ReactDOM.createRoot(document.querySelector(config.selector));
    root.render(
				<Provider store={store}>
        	<App config={config} />
				</Provider>
    );
}
